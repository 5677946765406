import nftfyLogo from '@assets/nftfy/nftfy-gray.svg'
import { isUrl, safeIpfsUrl } from '@services/UtilService'
import { useCallback, useEffect, useState } from 'react'
import { Media3d } from './Media3d'
import { MediaImage } from './MediaImage'
import { MediaVideo } from './MediaVideo'

export const Media = ({ url, showPreview }: { url?: string; showPreview?: boolean }) => {
  const [mimeType, setMimeType] = useState<string | null>(null)
  const [uri, setUri] = useState<string | null>(null)
  const getMimeType = useCallback(async (): Promise<string> => {
    const verifyMP4 = () => {
      const verifyVideo = uri && uri.indexOf('.mp4') > -1
      return verifyVideo ? 'video/mp4' : null
    }
    const verifyMP3 = () => {
      const verifyAudio = uri && uri.indexOf('.mp3') > -1
      return verifyAudio ? 'audio/mp3' : null
    }
    const verify3d = () => {
      const verify3D = (uri && uri.indexOf('.glb') > -1) || (uri && uri.indexOf('.gltf') > -1)
      return verify3D ? 'model/gltf+json' : null
    }
    const verifyImage = () => {
      const verify =
        (uri && uri.indexOf('.gif') > -1) ||
        (uri && uri.indexOf('.png') > -1) ||
        (uri && uri.indexOf('.jpg') > -1) ||
        (uri && uri.indexOf('.jpeg') > -1) ||
        (uri && uri.indexOf('.svg') > -1) ||
        (uri && uri.indexOf('.webp') > -1) ||
        (uri && uri.indexOf('.bmp') > -1)
      return verify ? 'image/jpeg' : null
    }

    const findType = async () => {
      const request = new Promise<string>((resolve, reject) => {
        const handleEvent = (event: ProgressEvent) => {
          reject(event)
        }
        if (!uri) {
          reject()
          throw new Error('No URI')
        }
        const xhr = new XMLHttpRequest()
        xhr.open('GET', uri, true)
        xhr.responseType = 'blob'
        xhr.onload = () => {
          const requestType = xhr.getResponseHeader('content-type')
          if (requestType) {
            resolve(requestType)
          }
          resolve('application/octet-stream')
        }
        xhr.addEventListener('error', handleEvent)
        xhr.send()
        xhr.status
      })
      const response = await request
      return response
    }

    // check common types
    let type = verifyMP4() || verifyMP3() || verify3d() || verifyImage()
    if (!type) {
      type = await findType()
    }
    return type
  }, [uri])

  const handleRender = () => {
    let render: JSX.Element | null = <MediaImage url='#' />
    if (!uri) {
      return render
    }
    switch (mimeType) {
      case 'image/gif':
      case 'image/png':
      case 'image/bmp':
      case 'image/webp':
      case 'image/jpeg':
        render = <MediaImage url={uri} showPreview={showPreview} />
        break
      case 'video/mp4':
        render = <MediaVideo url={uri} />
        break
      case 'audio/mp3':
        render = (
          <audio autoPlay muted controls>
            <source src={uri} type='audio/mp3' />
          </audio>
        )
        break
      case 'model/gltf+json':
      case 'model/gltf-binary':
        render = <Media3d url={uri} />
        break
      default:
        render = <MediaImage url={uri} />
        break
    }
    return render
  }

  useEffect(() => {
    const getImage = async () => {
      if (!url) {
        setUri(nftfyLogo.src)
        setMimeType(await getMimeType())
        return
      }
      const checkedUrl = isUrl(url) ? url : `https://ipfs.io/ipfs/${url}`
      setUri(safeIpfsUrl(checkedUrl))
      setMimeType(await getMimeType())
    }

    getImage()
  }, [getMimeType, url])

  return <>{mimeType && handleRender()}</>
}
