import { useReactiveVar } from '@apollo/client'
import { themeVar } from '@graphql/variables/SharedVariable'
import { imgLH3, networkNameById, toHumanFormat } from '@services/UtilService'
import React, { ReactNode, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import Icon, { Icons } from '../shared/Icon'
import { Media } from './media/Media'

export interface CardAction {
  id: string
  name: string
  action: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  showAction?: boolean
}
export interface CardBaseProps {
  totalMembers?: number
  urlRedirect?: string
  totalNfts: number
  network: number
  maxWidth?: string
  animationUrl?: string
  imageUrl: string
  children: ReactNode
  actions?: CardAction[]
  supported?: boolean
  disabled?: boolean
}
export default function CardBase({
  totalMembers,
  totalNfts,
  network,
  imageUrl,
  children,
  actions,
  supported = true,
  urlRedirect,
  animationUrl,
  maxWidth,
  disabled
}: CardBaseProps) {
  const theme = useReactiveVar(themeVar)

  const handleRedirect = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    disabled && e.preventDefault()
  }

  const [image, setImage] = useState<string>(imageUrl)

  useEffect(() => {
    setImage(imgLH3(imageUrl, 300, 300))
  }, [imageUrl])

  return (
    <Container href={!disabled ? urlRedirect || undefined : ''} onClick={e => handleRedirect(e)} disabled={disabled}>
      <Card>
        <Header>
          <div>
            {!!totalMembers && supported && (
              <LabelMembers className={`${theme}`}>
                <Icon size={14} name={Icons.members} />
                <span>{toHumanFormat(totalMembers)}</span>
              </LabelMembers>
            )}
            {!supported && (
              <NotSupported>
                <Icon size={13} name={Icons.warning} />
                <span>Not supported</span>
              </NotSupported>
            )}
          </div>
          <div>{!!totalNfts && <LabelNFTs>{`${toHumanFormat(totalNfts)} ${totalNfts > 1 ? 'NFTs' : 'NFT'}`}</LabelNFTs>}</div>
          <Icon size={24} name={networkNameById(network)} />
        </Header>

        <ImageContainer maxWidth={maxWidth}>
          <Media url={animationUrl ?? image} />
        </ImageContainer>
        {!!actions && (
          <Actions>
            <div>
              <div>
                {actions.map(
                  action =>
                    action.showAction && (
                      <ActionButton
                        key={`action-${action.id}`}
                        onClick={e => {
                          action.action(e)
                        }}
                      >
                        {action.name}
                      </ActionButton>
                    )
                )}
              </div>
            </div>
          </Actions>
        )}
        <Footer>{children}</Footer>
      </Card>
    </Container>
  )
}

export const {
  Container,
  Card,
  Header,
  LabelMembers,
  LabelNFTs,
  ImageContainer,
  Image,
  Footer,
  Actions,
  ActionButton,
  NotSupported,
  Video
} = {
  Container: styled.a<{ disabled?: boolean }>`
    ${props =>
      props.disabled &&
      css`
        &::before {
          content: '';
          z-index: 2;
          opacity: 0.5;
          background-color: black;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 16px;
        }
      `}

    position: relative;
    @media (max-width: ${props => props.theme.viewport.tablet}) {
      > div {
        box-shadow: 0 0 3px rgba(33, 33, 33, 0.3);
      }

      > div:nth-child(1) > div:nth-child(2) > img {
        transform: scale(1.1);
        filter: saturate(1.4);
      }

      > div:nth-child(1) > div:nth-child(3) > div > div {
        transform: translateY(0px);
        opacity: 1;
        z-index: 11;
      }
    }

    &:hover {
      > div {
        box-shadow: 0 0 3px rgba(33, 33, 33, 0.3);
      }

      > div:nth-child(1) > div:nth-child(2) > img {
        transform: scale(1.1);
        filter: saturate(1.4);
      }

      > div:nth-child(1) > div:nth-child(3) > div > div {
        transform: translateY(0px);
        opacity: 1;
        z-index: 11;
      }
    }
  `,
  Card: styled.div`
    background: ${props => props.theme.colors.gray[1]};
    border-radius: 16px;
    transition: box-shadow 0.1s;
  `,
  Header: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 24px;
    gap: 5px;
    align-items: center;

    padding: 12px 16px;
    height: 48px;
    background: ${props => props.theme.colors.gray[1]};
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    > div:nth-child(2) {
      display: inline-flex;
      justify-content: end;
    }
  `,
  NotSupported: styled.div`
    background: ${props => props.theme.colors.red.main};
    padding: 0 8px;
    line-height: 24px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 2px;
    span {
      font-weight: 400;
      font-size: 10px;
      color: ${props => props.theme.colors.constants.white};
    }
  `,
  LabelMembers: styled.div`
    background: ${props => props.theme.colors.white};
    width: auto;
    height: 24px;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 0 12px;

    border-radius: 8px;

    span {
      color: ${props => props.theme.colors.black};
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
    }

    &.light {
      img {
        filter: invert(1);
      }
    }
  `,
  LabelNFTs: styled.div`
    background: ${props => props.theme.colors.white};
    width: auto;
    height: 24px;

    display: inline-flex;
    align-items: center;
    border-radius: 8px;

    padding: 0 12px;

    color: ${props => props.theme.colors.black};
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  `,
  ImageContainer: styled.div<{ actions?: CardAction[]; maxWidth?: string }>`
    width: 100%;
    background: ${props => props.theme.colors.gray[1]};
    mask-image: radial-gradient(circle, white, black);
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 263px;
    height: 270px;
    position: relative;
    > .ant-image-error {
      width: 80px;
    }
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
    ${props =>
      !props.actions &&
      css`
        :hover {
          > div,
          > img,
          > video {
            transform: scale(1.2);
            position: absolute;
            overflow: hidden;
            transition: transform 0.2s;
          }
        }
      `}
      > div,
    > img,
    > video {
      width: 100%;
      display: flex;
      height: 270px;
      align-items: center;
      transition: transform 0.2s;
    }
  `,
  Image: styled.img<{ bigCard: boolean }>`
    width: 100%;
    height: ${props => (props.bigCard ? 'auto' : '272px')};
    transition: all 0.1s ease-in-out;
    object-position: center;
    object-fit: cover;
  `,
  Footer: styled.div``,
  Actions: styled.div`
    width: 1px;
    height: 1px;
    margin: 0 auto;

    div {
      position: absolute;
      margin-top: -100px;
      margin-left: -40px;
      z-index: 10;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      align-items: center;
      justify-content: center;

      div {
        width: 160px;
        height: 100px;
        display: grid;
        grid-template-columns: 1fr;
        align-items: flex-end;
        gap: 20px;
        transition: all 0.1s ease-in-out !important;
        transform: translateY(30px);
        opacity: 0;
        z-index: -1;
      }
    }
  `,
  ActionButton: styled.button`
    background-color: ${props => props.theme.colors.blue.main};
    color: ${props => props.theme.colors.constants.white};
    border-radius: 16px;
    border: 0 solid;
    padding: 12px;
    font-size: 16px;
    position: relative;
    z-index: 11;
    cursor: pointer;
    transition: all 0.2s ease-in-out !important;
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);

    &:hover {
      background-color: ${props => props.theme.colors.blue.dark};
    }
  `,
  Video: styled.video`
    width: 100%;
    height: auto;
    object-fit: cover;
  `
}
