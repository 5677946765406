import styled from 'styled-components'

export const MediaVideo = ({ url }: { url: string }) => {
  return (
    <Container autoPlay loop muted controls>
      <source src={url} type='video/mp4' />
    </Container>
  )
}
const { Container } = {
  Container: styled.video`
    width: 100%;
    height: auto;
  `
}
