import nftfyLogo from '@assets/nftfy/nftfy-gray.svg'
import { Image } from 'antd'
import styled from 'styled-components'

export const MediaImage = ({ url, showPreview }: { url: string; showPreview?: boolean }) => {
  return (
    <>
      <Container
        src={url || nftfyLogo.src}
        fallback={nftfyLogo.src}
        preview={!!showPreview}
        loading='lazy'
        placeholder={<Image preview={false} src={url || nftfyLogo.src} />}
      />
    </>
  )
}
const { Container } = {
  Container: styled(Image)`
    width: 100%;
    height: auto;
  `
}
