import { OrbitControls, useGLTF } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import { Suspense } from 'react'
import styled from 'styled-components'

export const Media3d = ({ url }: { url: string }) => {
  // eslint-disable-next-line react/no-multi-comp
  const Model = () => {
    const { scene } = useGLTF(url)
    return <primitive object={scene} />
  }
  return (
    <Container>
      <Canvas camera={{ position: [255, 255, 450], fov: 1 }}>
        <pointLight position={[255, 255, 450]} intensity={3} />
        <Suspense fallback={null}>
          <Model />
        </Suspense>
        <OrbitControls />
      </Canvas>
    </Container>
  )
}
const { Container } = {
  Container: styled.div`
    width: 100%;
    height: auto;
  `
}
